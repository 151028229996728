.DraftEditor-root {
  position: relative;
  .public-DraftEditorPlaceholder-root {
    opacity: 0.33;
    position: relative;
    width: 100%;
    pointer-events: none;
  }
  .DraftEditor-editorContainer {
    &:not(:first-child) {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      * {
        height: 100%;
      }
    }
    > * {
      white-space: normal !important;
    }
    .public-DraftEditor-content > * {
      pointer-events: none;
    }
  }
}
