#editor-header {
  position: fixed;
  z-index: 50;
  top: 0;
  right: 0;
  left: 0;
  height: 63px;
  background: $color-ci-blue-gradient-vertical;
  box-shadow: $box-shadow-area-primary;
  color: #fff;
  .button.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 63px;
    height: 100%;
    min-width: 0;
    padding: 0;
    border-radius: 0;
    .icon {
      display: inline-block;
      position: relative;
      width: 21px;
      height: 14px;
      span {
        position: absolute;
        z-index: 15;
        width: 100%;
        height: 2px;
        background: #fff;
        border-radius: 1px;
        &:nth-child(2), &:nth-child(3) {
          top: 6px;
        }
        &:nth-child(4) {
          top: 12px;
        }
      }
    }
  }
  .wbt-title {
    @include font-light(17px);
    position: absolute;
    top: 0;
    left: 63px;
    width: calc(50% - 63px);
    padding: 20px 25px 18px;
    overflow: hidden;
  	letter-spacing: 0.06em;
    line-height: 25px;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .action-bar {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    .quick-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      > * {
        display: flex;
        margin: 0 10px;
      }
      .quick-actions-item {
        position: relative;
        height: 100%;
        .count {
          position: absolute;
          top: 15px;
          right: -5px;
          width: 15px;
          height: 15px;
          background: $color-ci-turquoise;
          font-weight: 700;
          font-size: 10px;
          line-height: 15px;
          color: #fff;
          border-radius: 50%;
          text-align: center;
        }
        .quick-actions-window {
          position: absolute;
          top: calc(100% - 8px);
          left: -10px;
          width: 200px;
          border-radius: 10px;
          background: $color-ci-blue;
          overflow: hidden;
          .headline {
            @include font-light(25px);
            padding: 18px 15px;
            text-align: center;
          }
          .responsive {
            max-height: 300px;
            overflow: auto;
            padding: 8px 0;
            border-top: 1px solid #4E74AF;
            li {
              text-align: center;
              button {
                @include font-light(16px);
                position: relative;
                width: 100%;
                padding: 12px 40px 12px 60px;
                line-height: 20px;
                text-align: left;
                color: #fff;
                transition: color $duration-hover;
                &:hover, &.active {
                  color: $color-ci-purple;
                  .icon svg path {
                    fill: $color-ci-purple;
                  }
                }
                &.inactive {
                  opacity: 0.5;
                }
                .icon {
                  display: inline-flex;
                  align-items: center;
                  margin-right: 10px;
                  vertical-align: middle;
                  svg path {
                    fill: #fff;
                    transition: fill $duration-hover;
                  }
                }
              }
            }
          }
          .actions, .comments {
            max-height: 300px;
            overflow: auto;
            border-top: 1px solid #4E74AF;
            li {
              &:nth-child(odd) {
                background: #0e203b;
              }
              button {
                @include font-light(16px);
                position: relative;
                width: 100%;
                padding: 20px 40px 20px 12px;
                line-height: 20px;
                text-align: left;
                color: #fff;
                transition: color $duration-hover;
                &:hover, &.active {
                  color: $color-ci-purple;
                  .icon svg path {
                    fill: $color-ci-purple;
                  }
                }
                &.inactive {
                  opacity: 0.5;
                }
                .icon {
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  width: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  svg path {
                    transition: fill $duration-hover;
                  }
                }
              }
            }
          }
          .comments .label {
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
            padding-right: 0px;
            overflow: hidden;
          }
        }
      }
      .quick-action-button {
        padding: 5px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &:not(.help) .icon svg {
          circle, path, rect {
            fill: #fff;
          }
        }
        &:hover, &.active {
          .icon svg path {
            fill: $color-ci-purple;
          }
        }
        .icon {
          display: flex;
          font-size: 0;
          svg path {
            transition: fill $duration-hover;
          }
        }
      }
    }
    .button-group {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      .button {
        min-width: 135px;
        margin: 0 15px;
        padding-right: 25px;
        padding-left: 25px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &.save {
          transition: opacity $duration-hover, color $duration-hover;
          &.saved {
            opacity: 0.5;
            pointer-events: none;
          }
        }
        &.export {
          width: 63px;
          min-width: 0;
          height: 100%;
          margin-right: -20px;
          padding-right: 10px;
          padding-left: 10px;
          .icon {
            display: flex;
            justify-content: center;
            margin-bottom: 5px;
          }
          .label {
            @include font-bold(8px);
          }
        }
      }
    }
  }
}
