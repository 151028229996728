* {
  box-sizing: border-box;
  outline: none !important;
  word-break: break-word;
}
html, body {
  @include font-regular(14px);
  color: $color-font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#body {
  min-height: 100vh;
}
#root {
  min-height: 100vh;
  overflow: hidden;
  background: $color-background-grey;
}
.tui-editor {
  height: 470px !important;
  width: 100%;
  .tui-image-editor-container {
    .tui-image-editor-controls {
      background: linear-gradient(0, rgba(28, 37, 51, 1) 0%, rgba(20, 40, 71, 1) 100%);
      .tui-image-editor-item.active {
        background: none;
      }
      .tui-image-editor-menu {
        padding: 0 190px 0 25px;
      }
      .tui-image-editor-menu use.normal.use-default {
        fill: #fff;
        stroke: #fff;
      }
      .tui-image-editor-menu use.active.use-default {
        fill: #fc45f0;
        stroke: #fc45f0;
      }
      .tui-image-editor-item {
        padding: 7px 5px 3px 5px;
      }
    }
    label {
      font-weight: 700 !important;
      color: #fff !important;
      text-transform: uppercase !important;
    }
    .tui-image-editor-main-container {
      background: $color-ci-blue !important;
      .tui-image-editor-main {
        top: 0;
        .tui-image-editor-size-wrap {
          height: 100%;
          padding-bottom: 124px;
          .tui-image-editor-align-wrap {
            vertical-align: top;
            font-size: 0;
            .tui-image-editor {
              top: 0 !important;
            }
          }
        }
        .color-picker-control {
          padding: 10px;
        }
        .tui-image-editor-submenu {
          height: 115px;
          > div {
            background: none;
            background: $color-ci-blue;
            padding: 5px 0;
            vertical-align: middle;
            .tui-colorpicker-clearfix {
              white-space: normal;
              li input {
                width: 13px;
                height: 13px;
                vertical-align: middle;
                cursor: pointer;
              }
            }
          }
          .tui-colorpicker-palette-hex {
            margin: 0;
            padding: 0;
          }
          .tui-image-editor-range-value {
            background: none;
            border: 1px solid #fff;
            color: #fc45f0;
            border-radius: 0;
            height: 18px;
            width: 25px;
          }
        }
        .color-picker-value {
          margin-bottom: 6px;
        }
      }
      div.tui-colorpicker-clearfix {
        width: 100%;
      }
    }
  }
}
