.dashboard {
  max-width: 1014px !important;
  .sections .section {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
    > h2 {
      @include font-headline-secondary();
      text-align: center;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    .quick-actions {
      margin: -8px;
      text-align: center;
      .quick-action {
        position: relative;
        width: 150px;
        height: 150px;
        margin: 8px;
        padding: 25px;
        vertical-align: top;
        color: $color-ci-blue;
        transition: color $duration-hover;
        &:hover {
          color: #fff;
          &:before {
            opacity: 1;
          }
          .icon svg {
            path, rect {
              fill: #fff;
            }
          }
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          bottom: 50%;
          left: 0;
          padding: 0 14px;
          font-size: 0;
          svg {
            path, rect {
              fill: $color-ci-blue;
              transition: fill $duration-hover;
            }
          }
        }
        .label {
          @include font-bold(12px);
          position: absolute;
          top: calc(50% + 20px);
          right: 0;
          left: 0;
          padding: 0 30px;
          text-transform: uppercase;
          line-height: 1.25;
        }
      }
    }
  }
}
