#editor-panel {
  position: fixed;
  z-index: 100;
  top: 81px;
  bottom: 18px;
  left: 18px;
  width: 280px;
  overflow: hidden;
  background: $color-ci-blue;
  color: #fff;
  transition: transform $duration-slide;
  &.preview-mode {
    transform: translateX(-316px);
  }
  &.view-edit .panel {
    &.panel-elements {
      transform: translateX(-100%);
    }
    &.panel-edit {
      transform: none;
    }
  }
  &.view-comment .panel {
    &.panel-elements {
      transform: translateX(-100%);
    }
    &.panel-comment {
      transform: none;
    }
  }
  input[type="text"], input[type="email"], input[type="password"], textarea, .input-wysiwyg {
    &.light {
      border-bottom-color: rgba(255, 255, 255, 0.5);
    }
  }
  select.light {
    border-color: rgba(255, 255, 255, 0.5);
  }
  .panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-ci-blue;
    transition: transform $duration-slide;
    &.panel-elements .panel-container {
      padding: 25px 30px;
      .sections .section {
        &:not(:last-child) {
          margin-bottom: 45px;
        }
        .headline {
          @include font-headline-secondary();
          margin-bottom: -5px;
        }
        .elements {
          display: flex;
          flex-flow: wrap;
          max-width: 172px;
          margin: 30px auto 0;
          .element-source {
            position: relative;
            &.is-dragging .element {
              .drag-preview-container {
                opacity: 0;
                transition: none;
              }
            }
            .element {
              position: relative;
              z-index: 5;
              &:before {
                position: absolute;
                top: 3px;
                right: 3px;
                bottom: 3px;
                left: 3px;
                border: 1px solid $color-background-darkblue;
                content: "";
              }
              &:after {
                position: absolute;
                top: 3px;
                right: 3px;
                bottom: 3px;
                left: 3px;
                box-shadow: $box-shadow-drag-preview-slot;
                content: "";
              }
              &.hover .drag-preview-container {
                .icon svg {
                  circle, path, rect {
                    fill: #fff !important;
                  }
                  line {
                    stroke: #fff !important;
                  }
                }
                .background:before {
                  opacity: 1;
                }
              }
              &.active {
                z-index: 10;
                &:before {
                  opacity: 1;
                }
                .drag-preview-container {
                  transform: scale(1.15);
                  &:before {
                    opacity: 1;
                  }
                }
              }
              .drag-preview-container {
                transition: opacity $duration-hover, transform $duration-hover;
                &:before {
                  opacity: 0;
                }
                .icon svg {
                  circle, path, rect {
                    fill: $color-ci-pink;
                    transition: fill $duration-hover;
                  }
                  line {
                    stroke: $color-ci-pink;
                    transition: stroke $duration-hover;
                  }
                }
                .background:before {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
    &.panel-edit, &.panel-comment {
      left: -1px;
      border-left: 1px solid $color-border-blue;
      transform: translateX(100%) translateX(1px);
      &.type-module .panel-header {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000;
        border-bottom: 1px solid #fff;
        .label {
          @include font-light(25px);
          text-transform: uppercase;
        }
      }
      &.type-motivation .panel-header {
        background: $color-ci-motication-gradient-diagonal;
        border: none;
      }
      .panel-header {
        height: 100px;
        padding: 15px 20px;
        background: $color-ci-purple-gradient-diagonal;
        text-align: center;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          font-size: 0;
          svg {
            transform: scale(1.5);
            circle, path, rect {
              fill: #fff;
              transition: fill $duration-hover;
            }
            line {
              stroke: #fff !important;
              transition: stroke $duration-hover;
            }
          }
        }
        .label {
          @include font-bold(12px);
          text-transform: uppercase;
        }
      }
      .panel-container {
        height: calc(100% - 100px);
        padding-bottom: 110px;
        .sections {
          .watch-video {
            @include font-bold(10px);
            color: $color-ci-pink;
            text-transform: uppercase;
            margin-top: 18px;
            .icon {
              vertical-align: middle;
              margin-top: -2px;
              margin-right: 5px;
              display: inline-flex;
            }
          }
          .section {
            padding: 20px 30px;
            &:not(:last-child) {
              border-bottom: 1px solid $color-border-blue;
            }
            &.section-header {
              position: absolute;
              right: 0;
              bottom: 100%;
              left: 0;
              padding-right: 20px;
              padding-left: 20px;
              .headline {
                text-transform: uppercase;
              }
            }
            .headline {
              @include font-headline-secondary();
              margin-bottom: -5px;
              text-transform: none;
              &:not(:last-child) {
                margin-bottom: 12px;
              }
            }
            .description {
              @include font-light(11px);
              text-align: left;
              &:not(:last-child) {
                margin-bottom: 20px;
              }
            }
            .field {
              display: flex;
              flex-direction: column;
              &:not(:last-child) {
                margin-bottom: 20px;
              }
              .input {
                &.input-image {
                  display: flex;
                  align-items: center;
                  justify-content: left;
                  .preview {
                    float: left;
                    height: 22px;
                    border: 1px solid #fff;
                    cursor: pointer;
                    img {
                      height: 100%;
                    }
                  }
                  .title {
                    @include font-regular(9px);
                    flex: 1;
                    padding: 0 15px;
                    text-align: left;
                    cursor: pointer;
                  }
                  button {
                    width: 27px;
                    height: 27px;
                    padding: 8px;
                    &:last-child {
                      margin-right: -8px;
                    }
                    svg {
                      float: left;
                      width: 100%;
                      height: 100%;
                      path {
                        fill: #fff;
                      }
                    }
                  }
                }
                &.input-switch {
                  user-select: none;
                  input[type="checkbox"] {
                    display: none;
                    &:checked + label > .switch {
                      &:before {
                        opacity: 1;
                      }
                      .rail .label {
                        &.label-on {
                          opacity: 1;
                        }
                        &.label-off {
                          opacity: 0;
                        }
                      }
                      .handle {
                        left: calc(100% - 18px);
                      }
                    }
                  }
                  label {
                    @include font-light(13px);
                    display: inline-flex;
                    position: relative;
                    cursor: pointer;
                    > .label {
                      margin-right: 20px;
                      line-height: 20px;
                    }
                    > .switch {
                      position: relative;
                      overflow: hidden;
                      border-radius: 10px;
                      white-space: nowrap;
                      &:before {
                        opacity: 0.5;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: $color-ci-purple-gradient-diagonal;
                        content: "";
                        transition: opacity $duration-hover;
                      }
                      .rail {
                        position: relative;
                        padding: 0 6px;
                        .label {
                          @include font-bold(9px);
                          display: block;
                          width: 100%;
                          padding: 0 2px;
                          text-align: center;
                          line-height: 20px;
                          text-transform: uppercase;
                          transition: opacity $duration-hover;
                          &.label-on {
                            opacity: 0;
                            padding-right: 18px;
                          }
                          &.label-off {
                            margin-top: -20px;
                            padding-left: 18px;
                          }
                        }
                      }
                      .handle {
                        position: absolute;
                        top: 3px;
                        left: 4px;
                        width: 14px;
                        height: 14px;
                        background: #fff;
                        border: 1px solid $color-border-grey;
                        border-radius: 7px;
                        transition: left $duration-hover;
                      }
                    }
                  }
                }
                &.input-teaser-types {
                  .label {
                    @include font-light(13px);
                    text-align: left;
                    &:not(:last-child) {
                      margin-bottom: 5px;
                    }
                  }
                  .teaser-types {
                    display: flex;
                    justify-content: space-between;
                    .teaser-type {
                      &.selected svg g {
                        g, path {
                          stroke: $color-ci-pink;
                        }
                      }
                      > * {
                        pointer-events: none;
                      }
                      svg g {
                        g, path {
                          fill: none;
                          stroke: #fff;
                        }
                      }
                    }
                  }
                }
                &.input-wysiwyg {
                  text-align: left;
                  .wysiwyg-buttons {
                    margin-bottom: 15px;
                    .row {
                      &:not(:last-child) {
                        margin-bottom: -1px;
                      }
                      .wysiwyg-button {
                        width: 26px;
                        height: 26px;
                        border: 1px solid #fff;
                        color: #fff;
                        vertical-align: middle;
                        transition: background $duration-hover, color $duration-hover;
                        &:hover {
                          background: #fff;
                          color: $color-font-primary;
                          .icon svg {
                            circle, text {
                              fill: $color-font-primary;
                            }
                            line {
                              stroke: $color-font-primary;
                            }
                          }
                        }
                        &:not(:last-child) {
                          margin-right: -1px;
                        }
                        &.wysiwyg-button-bold {
                          font-weight: bold;
                        }
                        &.wysiwyg-button-italic {
                          font-style: italic;
                        }
                        &.wysiwyg-button-underline {
                          text-decoration: underline;
                        }
                        .icon {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 100%;
                          height: 100%;
                          svg {
                            circle, text {
                              fill: #fff;
                              transition: fill $duration-hover;
                            }
                            line {
                              stroke: #fff;
                              transition: stroke $duration-hover;
                            }
                          }
                        }
                      }
                    }
                  }
                  .DraftEditor-root {
                    position: relative;
                    .public-DraftEditorPlaceholder-root {
                      opacity: 1;
                      color: $color-ci-pink;
                    }
                    div[data-contents] {
                      > *:not(:last-child) {
                        margin-bottom: 10px;
                      }
                      h1 {
                        @include font-regular(21px);
                      }
                      h2 {
                        @include font-regular(18px);
                      }
                      h3 {
                        @include font-regular(15px);
                      }
                      ul li {
                        position: relative;
                        padding-left: 15px;
                        &:before {
                          position: absolute;
                          top: 6px;
                          left: 0;
                          width: 4px;
                          height: 4px;
                          border-radius: 50%;
                          background: #fff;
                          content: "";
                        }
                      }
                      ol {
                        counter-reset: list-item;
                        li {
                          position: relative;
                          &:before {
                            display: inline-block;
                            position: relative;
                            width: 15px;
                            font-weight: bold;
                            content: counter(list-item) ".";
                          }
                          > * {
                            display: inline-block;
                          }
                        }
                      }
                    }
                  }
                }
                &.input-select {
                  position: relative;
                  select {
                    width: 100%;
                    padding-right: 24px;
                  }
                  .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    right: 8px;
                    bottom: 0;
                    width: 8px;
                    svg path {
                      fill: none;
                      stroke: #fff;
                    }
                  }
                }
              }
              .coordinates {
                margin: 0 -10px;
                .coordinate {
                  width: calc(50% - 20px);
                  display: flex;
                  margin: 0 10px;
                  &.coordinate-x {
                    float: left;
                  }
                  &.coordinate-y {
                    float: right;
                  }
                  .label {
                    @include font-regular(14px);
                    width: 15px;
                    padding: 3px 0;
                    text-align: left;
                    line-height: 14px;
                    color: #fff;
                  }
                  input {
                    width: calc(100% - 15px);
                  }
                }
              }
              .items {
                margin-bottom: 35px;
              }
            }
            .comments {
              padding: 0 12px 0 0;
              .comment {
                position: relative;
                padding: 10px;
                background: #fff;
                border-radius: 7px;
                color: #142847;
                &:not(:last-child) {
                  margin-bottom: 12px;
                }
                .checked {
                  position: absolute;
                  top: 0;
                  right: 0;
                  margin-top: -8px;
                  margin-right: -8px;
                }
                .comment-head {
                  display: flex;
                  font-weight: 300;
                  font-size: 9px;
                  text-align: left;
                  border-bottom: 1px solid #DCE0E6;
                  padding-bottom: 6px;
                  margin-bottom: 8px;
                  .image {
                    width: 28px;
                    height: 28px;
                    background: transparent url() no-repeat center;
                    background-size: cover;
                    border-radius: 50%;
                  }
                  .column-left {
                    width: calc(50% - 14px);
                    padding-left: 10px;
                    .strong {
                      font-weight: 600;
                    }
                  }
                  .column-right {
                    width: calc(50% - 14px);
                    padding-left: 5px;
                    .strong {
                      font-weight: 600;
                    }
                  }
                }
                .comment-body {
                  padding-bottom: 35px;
                  font-size: 9px;
                  position: relative;
                  .text {
                    text-align: left;
                  }
                  .button {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 100px;
                    min-width: 0;
                    margin: 10px auto 0;
                    margin-left: -50px;
                    padding: 7px 10px 5px;
                    text-align: center;
                    font-size: 9px;
                    &:before {
                      background: $color-ci-turquoise-gradient-diagonal;
                      border: none !important;
                    }
                  }
                  .delete-comment {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
      .panel-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 80px;
        padding: 10px 30px;
        background: $color-ci-blue;
        &:before {
          opacity: 0.5;
          position: absolute;
          right: 0;
          bottom: 100%;
          left: 0;
          height: 50px;
          background: linear-gradient(0deg, rgba(10, 26, 52, 1) 0%, rgba(20, 40, 71, 0) 100%);
          content: "";
          pointer-events: none;
        }
      }
    }
    &.panel-comment {
      .panel-header {
        background: $color-ci-turquoise-gradient-diagonal;
        .icon svg {
          width: 36px;
          transform: none;
        }
      }
      .panel-body {
        .button:before {
          background: $color-ci-purple-gradient-diagonal;
          border-color: $color-border-purple;
        }
      }
    }
    .panel-container {
      height: 100%;
      text-align: center;
    }
  }
}
.double-button {
  display: flex;
  flex: 1;
  .field {
    width: 50%;
  }
  .icon {
    margin-bottom: 3px !important;
  }
  .label {
    font-size: 9px;
  }
}
.send-comment {
  z-index: 0;
  min-width: 0;
  margin: 10px auto 0;
  padding: 7px 15px 5px;
  text-align: center;
  font-size: 9px;
  &:before {
    background: $color-ci-turquoise-gradient-diagonal;
    border: none !important;
  }
  .icon {
    vertical-align: middle;
    font-size: 0;
    height: 15px;
    margin-left: 8px;
    margin-top: -2px;
  }
}
.repeater-item {
  z-index: 1000;
  position: relative;
  text-align: center;
  &:not(:last-child) {
    margin-bottom: 35px;
  }
  &.simplified {
    display: flex;
    flex-direction: row-reverse;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .item-header {
      .headline {
        display: none;
      }
    }
    .item-body {
      flex: 1;
    }
  }
  &.v2 {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 27px;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .v2 {
      margin-bottom: 15px;
    }
    .field:not(:first-child) {
      margin-right: -27px !important;
    }
    .items {
      margin-bottom: 5px !important;
      margin-left: -8px;
    }
    .item-header {
      position: absolute;
      top: 0;
      right: 0;
      .headline {
        display: none;
      }
      .item-header-button-group {
        width: 27px;
        align-items: flex-start;
      }
    }
    .item-body {
      flex: 1;
    }
    .item-collapse {
      button {
        width: 23px;
        height: 23px;
        padding: 6px;
        font-size: 0;
        margin-left: -8px;
        margin-right: 4px;
        transform: rotate(180deg);
        transition: transform 0.25s;
        &.open {
          transform: rotate(270deg);
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &:before {
    position: absolute;
    z-index: -1;
    top: -15px;
    right: 0;
    bottom: -15px;
    left: 0;
    background: $color-ci-blue;
    content: "";
  }
  .item-header {
    display: flex;
    text-align: left;
    .item-headline {
      @include font-light(20px);
      width: calc(100% - 19px);
      margin-bottom: 5px;
      color: #fff;
    }
    .item-header-button-group {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 46px;
      .item-header-button {
        width: 27px;
        height: 27px;
        padding: 8px;
        &:last-child {
          margin-right: -8px;
        }
        &.move-item {
          cursor: move;
        }
        svg {
          float: left;
          width: 100%;
          height: 100%;
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .item-body .field:not(:last-child) {
    margin-bottom: 15px;
  }
  input[type="text"], input[type="email"], input[type="password"], textarea, .input-wysiwyg {
    &.light {
      border-bottom-color: rgba(255, 255, 255, 0.5);
    }
  }
  .field {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 20px !important;
    }
    .input {
      &.input-image {
        display: flex;
        align-items: center;
        justify-content: left;
        .preview {
          float: left;
          height: 22px;
          border: 1px solid #fff;
          cursor: pointer;
          img {
            height: 100%;
          }
        }
        .title {
          @include font-regular(9px);
          flex: 1;
          padding: 0 15px;
          text-align: left;
          cursor: pointer;
        }
        button {
          width: 27px;
          height: 27px;
          padding: 8px;
          &:last-child {
            margin-right: -8px;
          }
          svg {
            float: left;
            width: 100%;
            height: 100%;
            path {
              fill: #fff;
            }
          }
        }
      }
      &.input-switch {
        user-select: none;
        input[type="checkbox"] {
          display: none;
          &:checked + label > .switch {
            &:before {
              opacity: 1;
            }
            .rail .label {
              &.label-on {
                opacity: 1;
              }
              &.label-off {
                opacity: 0;
              }
            }
            .handle {
              left: calc(100% - 18px);
            }
          }
        }
        label {
          @include font-light(13px);
          display: inline-flex;
          position: relative;
          cursor: pointer;
          > .label {
            margin-right: 20px;
            line-height: 20px;
          }
          > .switch {
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            white-space: nowrap;
            &:before {
              opacity: 0.5;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: $color-ci-purple-gradient-diagonal;
              content: "";
              transition: opacity $duration-hover;
            }
            .rail {
              position: relative;
              padding: 0 6px;
              .label {
                @include font-bold(9px);
                display: block;
                width: 100%;
                padding: 0 2px;
                text-align: center;
                line-height: 20px;
                text-transform: uppercase;
                transition: opacity $duration-hover;
                &.label-on {
                  opacity: 0;
                  padding-right: 18px;
                }
                &.label-off {
                  margin-top: -20px;
                  padding-left: 18px;
                }
              }
            }
            .handle {
              position: absolute;
              top: 3px;
              left: 4px;
              width: 14px;
              height: 14px;
              background: #fff;
              border: 1px solid $color-border-grey;
              border-radius: 7px;
              transition: left $duration-hover;
            }
          }
        }
      }
      &.input-teaser-types {
        .label {
          @include font-light(13px);
          text-align: left;
          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
        .teaser-types {
          display: flex;
          justify-content: space-between;
          .teaser-type {
            &.selected svg g {
              g, path {
                stroke: $color-ci-pink;
              }
            }
            > * {
              pointer-events: none;
            }
            svg g {
              g, path {
                fill: none;
                stroke: #fff;
              }
            }
          }
        }
      }
      &.input-wysiwyg {
        text-align: left;
        .wysiwyg-buttons {
          margin-bottom: 15px;
          .row {
            &:not(:last-child) {
              margin-bottom: -1px;
            }
            .wysiwyg-button {
              width: 26px;
              height: 26px;
              border: 1px solid #fff;
              color: #fff;
              vertical-align: middle;
              transition: background $duration-hover, color $duration-hover;
              &:hover {
                background: #fff;
                color: $color-font-primary;
                .icon svg {
                  circle, text {
                    fill: $color-font-primary;
                  }
                  line {
                    stroke: $color-font-primary;
                  }
                }
              }
              &:not(:last-child) {
                margin-right: -1px;
              }
              &.wysiwyg-button-bold {
                font-weight: bold;
              }
              &.wysiwyg-button-italic {
                font-style: italic;
              }
              &.wysiwyg-button-underline {
                text-decoration: underline;
              }
              .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                svg {
                  circle, text {
                    fill: #fff;
                    transition: fill $duration-hover;
                  }
                  line {
                    stroke: #fff;
                    transition: stroke $duration-hover;
                  }
                }
              }
            }
          }
        }
        .DraftEditor-root {
          position: relative;
          .public-DraftEditorPlaceholder-root {
            opacity: 1;
            color: $color-ci-pink;
          }
          div[data-contents] {
            > *:not(:last-child) {
              margin-bottom: 10px;
            }
            h1 {
              @include font-regular(21px);
            }
            h2 {
              @include font-regular(18px);
            }
            h3 {
              @include font-regular(15px);
            }
            ul li {
              position: relative;
              padding-left: 15px;
              &:before {
                position: absolute;
                top: 6px;
                left: 0;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #fff;
                content: "";
              }
            }
            ol {
              counter-reset: list-item;
              li {
                position: relative;
                &:before {
                  display: inline-block;
                  position: relative;
                  width: 15px;
                  font-weight: bold;
                  content: counter(list-item) ".";
                }
                > * {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
      &.input-select {
        position: relative;
        select {
          width: 100%;
          padding-right: 24px;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 8px;
          bottom: 0;
          width: 8px;
          svg path {
            fill: none;
            stroke: #fff;
          }
        }
      }
    }
    .coordinates {
      margin: 0 -10px;
      .coordinate {
        width: calc(50% - 20px);
        display: flex;
        margin: 0 10px;
        &.coordinate-x {
          float: left;
        }
        &.coordinate-y {
          float: right;
        }
        .label {
          @include font-regular(14px);
          width: 15px;
          padding: 3px 0;
          text-align: left;
          line-height: 14px;
          color: #fff;
        }
        input {
          width: calc(100% - 15px);
        }
      }
    }
    .items {
      margin-bottom: 35px;
    }
  }
}
.react-datepicker-popper {
  background: #fff;
  width: 220px;
  margin: 0 !important;
  border: 1px solid $color-border-grey;
  .react-datepicker {
    max-width: 190px;
    font-family: 'DIn 2014';
    border-radius: 0;
    border: none;
    margin-top: 5px;
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__header  {
      background: #fff;
      border: none;
    }
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 20px;
    font-size: 9px;
    font-weight: 300;
    margin: 0;
    line-height: 20px;
    border-radius: 50%;
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected {
    background: $color-ci-turquoise;
  }
  .react-datepicker__day-names .react-datepicker__day-name {
    font-weight: 600;
    text-transform: uppercase;
  }
  .react-datepicker__current-month {
    font-size: 9px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}
.react-datepicker__navigation {
  margin-top: 0px;
  border-width: 5px;
}
.react-datepicker__navigation--previous {
  left: 5px;
  border-right-color: $color-ci-purple !important;
}
.react-datepicker__navigation--next {
  right: 5px;
  border-left-color: $color-ci-purple !important;
}
.comment .comment-files {
  margin-bottom: -5px;
  margin-top: 10px;
  .delete-file {
    display: none;
  }
}
.comment-files {
  text-align: left;
  font-size: 0;
  margin: -5px -5px 10px;
  .strong {
    font-size: 9px;
    font-weight: 700;
    padding: 0 5px;
    text-transform: uppercase;
  }
  .comment-file {
    position: relative;
    display: inline-block;
    width: 39px;
    height: 22px;
    margin: 5px;
    vertical-align: middle;
    &:hover .delete-file {
      opacity: 1;
    }
    .audio {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: #E3E5E6;
      cursor: pointer;
    }
    .image {
      width: 100%;
      height: 100%;
      background: #fff url() no-repeat center;
      background-size: cover;
      cursor: pointer;
    }
    .delete-file {
      position: absolute;
      top: -6px;
      right: -6px;
      background: $color-ci-pink;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      padding: 3px;
      cursor: pointer;
      opacity: 0;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
