#header {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 50;
  top: 0;
  right: 0;
  left: 0;
  height: 63px;
  padding: 0 138px;
  background: $color-ci-blue-gradient-vertical;
  box-shadow: $box-shadow-area-primary;
  color: #fff;
  .logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 138px;
    height: 63px;
    a {
      display: block;
      height: 100%;
      padding: 15px;
      svg {
        width: 100%;
        height: 100%;
        text, path, rect {
          fill: #fff;
        }
      }
    }
  }
  .navigation {
    padding: 16px 0;
    ul {
      margin: 0 -8px;
      li {
        display: inline-block;
        margin: 0 8px;
        a {
          @include font-bold(12px);
          padding: 6px 10px 4px;
          color: #fff;
          text-transform: uppercase;
          transition: color $duration-hover;
          &:hover, &.active {
            color: $color-ci-pink;
          }
        }
      }
    }
  }
  .profile {
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    .button {
      width: 63px;
      height: 63px;
      min-width: 0;
      padding: 16px;
      .image {
        position: relative;
        z-index: 15;
        width: 100%;
        height: 100%;
        background: $color-background-grey;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &:hover .profile-menu {
      opacity: 1;
      pointer-events: auto;
    }
    .profile-menu {
      opacity: 0;
      position: absolute;
      top: 100%;
      right: 0;
      padding: 15px 30px;
      background: $color-ci-blue;
      pointer-events: none;
      transition: opacity $duration-hover;
      &:before {
        position: absolute;
        bottom: 100%;
        height: 63px;
        width: 100%;
        content: "";
      }
      ul li {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 5px;
        }
        button, a {
          display: flex;
          width: 100%;
          padding: 0 5px;
          &:hover {
            .icon svg path {
              fill: $color-ci-pink;
            }
            .label {
              color: $color-ci-pink;
            }
          }
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 14px;
            margin-right: 10px;
            font-size: 0;
            svg path {
              fill: #fff;
              transition: fill $duration-hover;
            }
          }
          .label {
            @include font-bold(12px);
            padding-top: 1px;
            white-space: nowrap;
            color: #fff;
            text-transform: uppercase;
            transition: color $duration-hover;
          }
        }
      }
    }
  }
}
