#headerimage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: calc(45vh - 63px);
  min-height: 250px;
  max-height: 500px;
  padding: 25px 50px 25px;
  box-shadow: $box-shadow-area-primary;
  .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-ci-blue url('../../images/background/macbook.jpg') no-repeat center;
    background-size: cover;
    &:before {
      opacity: 0.75;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $color-ci-blue;
      content: "";
    }
  }
  .headerimage-container {
    position: relative;
    width: 100%;
    .headline {
      @include font-headline-primary();
      color: #fff;
      text-align: center;
    }
    .user-dashboard {
      display: flex;
      justify-content: center;
      .column {
        width: 100%;
        min-height: 1px;
        .circle {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 156px;
          padding-top: 2px;
          border: 1px solid #fff;
          border-radius: 50%;
          margin: 0 auto;
          height: 156px;
          background: $color-ci-blue;
          &:not(:last-child) {
            margin-bottom: 25px;
          }
          &.profile-image {
            padding: 0;
            img {
              width: 100%;
              height: 100%;
              overflow: hidden;
              border-radius: 50%;
            }
          }
          .number {
            font-size: 60px;
            line-height: 60px;
            font-weight: 100;
            color: #fff;
            text-align: center;
          }
          .icon {
            position: absolute;
            top: 18px;
          }
          .label-top {
            position: absolute;
            top: 18px;
            color: #fff;
            padding: 0 35px;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 10px;
            text-align: center;
            line-height: 12px;
          }
          .label-bottom {
            position: absolute;
            bottom: 25px;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 12px;
          }
          .completed-to-dos-count {
            position: absolute;
            bottom: 15px;
            z-index: 10;
            font-size: 12px;
            font-weight: 700;
            left: 0;
            right: 4px;
            text-align: center;
            color: #22A6D6;
          }
        }
        .profile-name {
          text-align: center;
          color: #fff;
          font-size: 25px;
          font-weight: 300;
          text-transform: uppercase;
        }
        .completed-to-dos {
          position: absolute;
          bottom: 3px;
        }
      }
    }
  }
}
