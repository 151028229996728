.editor-overlay {
  opacity: 0;
  position: absolute;
  top: -12px;
  right: -42px;
  bottom: -12px;
  left: -42px;
  pointer-events: none;
  transition: opacity $duration-hover;
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: $box-shadow-area-tertirary;
    content: "";
    pointer-events: none;
  }
  &.show {
    opacity: 1;
    .editor-overlay-bar-button-group {
      pointer-events: auto;
    }
  }
  &.editor-overlay-element {
    top: -12px;
    right: -22px;
    bottom: -12px;
    left: -22px;
    .drag-button-bar {
      background: $color-ci-purple-gradient-vertical;
    }
    .editor-overlay-bar-button-group {
      border-color: $color-ci-purple;
      .editor-overlay-bar-button {
        &.highlighted {
          background: $color-ci-purple-gradient-diagonal;
        }
        svg {
          path, rect {
            fill: $color-ci-purple;
          }
        }
      }
    }
    .editor-overlay-background {
      border-color: $color-ci-purple;
    }
  }
  &.editor-overlay-column-element {
    top: -7px;
    right: -7px;
    bottom: -7px;
    left: -7px;
    .editor-overlay-bar .editor-overlay-bar-button {
      width: 25px;
    }
  }
  .drag-button-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: calc(100% - 2px);
    left: 0;
    height: 20px;
    background: $color-ci-blue-gradient-vertical;
    font-size: 0;
    pointer-events: auto;
    .drag-button-source {
      width: 20px;
      height: 20px;
      text-align: center;
      cursor: pointer;
      > .editor-overlay-bar-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 100%;
        width: 100%;
        height: 100%;
        pointer-events: none;
        svg {
          path, rect {
            fill: #fff;
          }
        }
      }
    }
  }
  .editor-overlay-bar-button-group {
    position: absolute;
    top: 2px;
    left: calc(100% - 2px);
    overflow: hidden;
    background: #fff;
    border: 2px solid $color-ci-blue;
    box-shadow: $box-shadow-area-tertirary;
    .editor-overlay-bar-button {
      width: 42px;
      height: 42px;
      transition: background $duration-hover, box-shadow $duration-hover;
      &.highlighted {
        background: $color-ci-blue-gradient-diagonal;
        &:hover {
          box-shadow: $box-shadow-button;
        }
        svg {
          path, rect {
            fill: #fff;
          }
        }
      }
      &.button-motivation {
        background: $color-ci-motication-gradient-diagonal;
      }
      &:not(.highlighted):not(:last-child) {
        position: relative;
        &:after {
          position: absolute;
          height: 1px;
          right: 1px;
          bottom: 0;
          left: 1px;
          background: $color-border-grey;
          content: "";
        }
      }
      &:not(.highlighted):hover {
        background: #F2F4F5;
      }
      &.button-comments {
        position: relative;
        .count {
          position: absolute;
          top: 3px;
          right: 3px;
          width: 15px;
          height: 15px;
          background: $color-ci-turquoise;
          font-weight: 700;
          font-size: 10px;
          line-height: 15px;
          color: #fff;
          border-radius: 50%;
          text-align: center;
        }
      }
      svg {
        path, rect {
          fill: $color-ci-blue;
        }
      }
    }
  }
  .editor-overlay-background {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid $color-ci-blue;
  }
}
#wbt.is-over .editor-overlay {
  opacity: 0;
}
