.element {
  &.edit-element .element-container-columns .columns .column:before {
    opacity: 1;
  }
  .element-container-columns {
    .columns {
      .column {
        position: relative;
        padding: 20px 0;
        transition: padding $duration-hover;
        &:first-child {
          z-index: 4;
        }
        &:nth-child(2) {
          z-index: 3;
        }
        &:nth-child(3) {
          z-index: 2;
        }
        &:nth-child(4) {
          z-index: 1;
        }
        &:before {
          opacity: 0;
          position: absolute;
          top: 15px;
          right: -5px;
          bottom: 15px;
          left: -5px;
          background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAIAAABv85FHAAAANklEQVQImWPYvHnzfxyA8f///wzYwJYtWxiw6oAYhkUObgu6HLL1DLgkUOQwHcyASwIqh8uLABGU1SNxMW8rAAAAAElFTkSuQmCC') repeat center;
          content: "";
          transition: opacity $duration-hover;
        }
        &.show-borders:before {
          opacity: 1;
        }
        &.new-column {
          *:not(.ditor-drop-indicator) {
            pointer-events: none;
          }
        }
        .element-empty .element-empty-container:before {
          opacity: 0;
        }
      }
    }
  }
}
