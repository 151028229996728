#wbt {
  &.is-over .wbt-container .modules .module .module-container {
    > h1 {
      pointer-events: none;
    }
    .elements .element .element-container {
      pointer-events: none;
      &.element-container-columns .columns .column {
        pointer-events: auto;
      }
    }
  }
  .wbt-container .modules .module {
    &.is-dragging .module-container {
      opacity: 0.33;
      pointer-events: none;
    }
    .module-container .elements .element.is-dragging {
      .element-container {
        opacity: 0.33;
        pointer-events: none;
      }
      .editor-overlay {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
