#overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;
  .overlay-window {
    position: relative;
    z-index: 10;
    width: 100%;
    max-width: 800px;
    padding: 40px 25px;
    background: #fff;
    text-align: center;
    .close-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      svg path {
        fill: #fff;
      }
    }
  }
  .overlay-background {
    opacity: 0.8;
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-ci-blue;
  }
}
