@mixin font-headline-primary() {
	font-family: 'DIN 2014', sans-serif;
	font-weight: 200;
	font-style: normal;
	font-size: 60px;
	line-height: 1.25;
	letter-spacing: 0.025em;
}
@mixin font-headline-secondary() {
	font-family: 'DIN 2014', sans-serif;
	font-weight: 300;
	font-style: normal;
	font-size: 25px;
	line-height: 1.25;
	letter-spacing: 0.025em;
	text-transform: uppercase;
}
@mixin font-extralight($size) {
	font-family: 'DIN 2014', sans-serif;
	font-weight: 200;
	font-style: normal;
	font-size: $size;
	line-height: 1.5;
	letter-spacing: 0.025em;
}
@mixin font-light($size) {
	font-family: 'DIN 2014', sans-serif;
	font-weight: 300;
	font-style: normal;
	font-size: $size;
	line-height: 1.5;
	letter-spacing: 0.025em;
}
@mixin font-regular($size) {
	font-family: 'DIN 2014', sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: $size;
	line-height: 1.75;
	letter-spacing: 0.025em;
}
@mixin font-bold($size) {
	font-family: 'DIN 2014', sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: $size;
	line-height: 1.75;
	letter-spacing: 0.025em;
}
