#editor {
  height: 100vh;
  &.preview #editor-preview {
    top: 0;
  }
  &.overlay-mode {
    &:before {
      opacity: .8;
      position: absolute;
      z-index: 5;
      top: 0;
      bottom: 0;
      left: 0;
      width: 316px;
      background: #142847;
      content: "";
    }
  }
}
