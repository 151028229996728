a, button {
  display: inline-block;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  text-decoration: none;
  appearance: none;
	cursor: pointer;
}
