@keyframes uploading {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(80%);
  }
}
#modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;
  .modal-window {
    position: relative;
    z-index: 10;
    width: 100%;
    max-width: 750px;
    padding: 40px 75px;
    background: #fff;
    text-align: center;
    &.external-link {
      .main input[type="text"] {
        max-width: 400px;
        text-align: center;
        display: inherit;
        margin: 0 auto 35px;
      }
    }
    &.help-video {
      max-width: 950px;
      padding: 50px 0 0;
      .main {
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
        position: relative;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    &.edit-image {
      max-width: 970px;
      padding: 0;
      .headline {
        font-family: 'DIN 2014', sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 25px;
        line-height: 50px;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
      }
      .button {
        position: absolute;
        bottom: 0;
        right: 10px;
        bottom: 12px;
      }
    }
    &.media-library {
      max-height: 100%;
      max-width: 1000px;
      overflow: auto;
      .attachments {
        display: flex;
        flex-flow: wrap;
        position: relative;
        margin: -8px;
        .attachment {
          display: flex;
          flex-direction: column;
          width: calc(20% - 16px);
          margin: 8px;
          padding-bottom: calc(20% - 16px);
          background: $color-background-grey none no-repeat center;
          background-size: cover;
          position: relative;
          &:hover:before {
            opacity: 1;
          }
          &:before {
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            box-shadow: $box-shadow-button;
            content: "";
            transition: opacity $duration-hover;
          }
          &.svg {
            background-size: auto;
          }
          .audio {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            width: 100%;
            height: 100%;
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              width: 100%;
              height: 80%;
              svg path {
                fill: #9a9a9a;
              }
            }
            .label {
              @include font-bold(10px);
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 33.333%;
              padding: 5px 15px;
              text-align: center;
            }
          }
          .video {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            .label {
              @include font-bold(10px);
              padding: 5px 15px;
              text-align: center;
            }
          }
        }
      }
    }
    &.settings {
      padding-top: 90px;
      .main {
        overflow: visible;
      }
    }
    &.export {
      .form > .field, .form > .fields > .field {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 20;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        .button.add-edit {
          float: left;
          margin-left: 0;
        }
        .category {
          @include font-regular(14px);
          text-align: left;
          text-transform: uppercase;
          margin-bottom: 12px;
        }
        .select-wrapper {
          position: relative;
          select {
            width: 100%;
            padding-right: 15px;
            padding-left: 0;
            border-top: none;
            border-right: none;
            border-left: none;
          }
          .arrow {
            position: absolute;
            top: 50%;
            right: 0;
            width: 9px;
            height: 5px;
            margin-top: -2px;
          }
        }
        > * {
          margin-bottom: 18px;
        }
      }
      .form .fields {
        display: flex;
        margin: 0 -20px;
        .field {
          width: 50%;
          margin-bottom: 20px;
          padding: 0 20px;
          select {
            padding: 4px 5px 2px;
          }
        }
      }
    }
    > .headline {
      @include font-headline-secondary();
      margin-bottom: 50px;
    }
    .filters {
      position: relative;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      padding: 10px 0;
      border-bottom: 1px solid $color-ci-blue;
      .filters-tabs {
        margin: 5px 0 -10px;
        .button {
          min-width: 145px;
          background: #F2F4F5;
          &:not(:last-child) {
            margin-right: 10px;
          }
          &.active {
            cursor: default;
            background: $color-ci-blue;
            &:before {
              border: transparent !important;
            }
            .label {
              color: #fff;
            }
            &:after {
              opacity: 0;
            }
          }
        }
      }
      .button {
        min-width: 0;
        padding: 12px 20px 10px;
      }
      .categories-filters {
        flex: 1;
        width: 25%;
        float: left;
        font-size: 0;
        padding: 5px 0;
        .filter-button {
          opacity: 0.4;
          padding: 5px 0;
          transition: opacity $duration-hover;
          &:not(:last-child) {
            margin-right: 20px;
          }
          &.active {
            opacity: 1;
          }
          .label {
            @include font-bold(12px);
            text-transform: uppercase;
          }
          .count {
            @include font-regular(12px);
            display: inline-block;
            margin-left: 10px;
            padding: 3px 10px 2px;
            background: $color-ci-blue;
            border-radius: 19px;
            line-height: 15px;
            color: #fff;
          }
        }
      }
      .main-filters {
        > * {
          float: left;
          width: auto;
          min-width: 160px;
          margin-left: 45px !important;
        }
        .input.search {
          position: relative;
          margin: 7px 0;
          input[type="search"] {
            padding: 3px 26px 2px 0;
            font-weight: 700;
            line-height: 20px;
            &::-webkit-input-placeholder {
              line-height: 20px;
              font-weight: 700;
              text-transform: uppercase;
            }
            &::-moz-placeholder {
              line-height: 20px;
              font-weight: 700;
              text-transform: uppercase;
            }
            &:-ms-input-placeholder {
              line-height: 20px;
              font-weight: 700;
              text-transform: uppercase;
            }
            &:-moz-placeholder {
              line-height: 20px;
              font-weight: 700;
              text-transform: uppercase;
            }
          }
          button {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            .icon svg path {
              fill: $color-ci-pink;
              stroke: $color-ci-pink;
            }
          }
        }
        .view {
          display: flex;
          min-width: auto;
          align-items: center;
          height: 26px;
          margin: 7px 0;
          .label {
            @include font-bold(12px);
            display: inline-block;
            line-height: 26px;
            text-transform: uppercase;
          }
          .view-buttons {
            margin-left: 12px;
            font-size: 0;
            > * {
              vertical-align: middle;
              &:not(:last-child) {
                margin-right: 10px;
              }
            }
            button {
              overflow: hidden;
              opacity: 0.4;
              transition: opacity $duration-hover;
              &.active {
                opacity: 1;
              }
              svg {
                float: left;
              }
            }
          }
        }
      }
    }
    .main {
      overflow: hidden;
      .files-drop {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 140px;
        cursor: pointer;
        #upload-form {
          width: 100%;
        }
        &:before {
          opacity: 0.75;
          position: absolute;
          z-index: -10;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAIAAABv85FHAAAANklEQVQImWPYvHnzfxyA8f///wzYwJYtWxiw6oAYhkUObgu6HLL1DLgkUOQwHcyASwIqh8uLABGU1SNxMW8rAAAAAElFTkSuQmCC') repeat center;
          content: "";
          transition: opacity $duration-hover;
        }
        &:after {
          opacity: 0;
          position: absolute;
          z-index: -5;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: $color-ci-purple;
          content: "";
          transition: opacity $duration-hover;
        }
        &.drag-over {
          &:after {
            opacity: 1;
          }
          .text {
            color: #fff;
          }
        }
        &.uploading {
          &:before {
            display: none;
          }
          .text {
            display: none;
          }
          .loading-bar {
            display: block;
          }
        }
        .text {
          @include font-regular(14px);
          padding: 25px;
          text-transform: uppercase;
          transition: color $duration-hover;
        }
        .loading-bar {
          display: none;
          position: relative;
          width: 80%;
          height: 4px;
          background: $color-ci-blue;
          &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $color-ci-purple-gradient-diagonal;
            content: "";
            transform: scaleX(80%);
            transform-origin: left center;
            animation-name: uploading;
            animation-duration: 5s;
          }
        }
        input {
          display: none;
        }
      }
      .form {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        font-size: 0;
        &.image-upload {
          display: none;
        }
        input[type="text"] {
          margin-bottom: 35px;
        }
      }
      .tab-buttons {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 50px;
        padding-right: 50px;
        display: flex;
        box-shadow: $box-shadow-area-primary;
        .tab-button {
          @include font-bold(12px);
          line-height: 47px;
          padding: 3px 0 0;
          flex: 1;
          background: $color-background-grey;
          text-transform: uppercase;
          cursor: pointer;
          &:not(:last-child) {
            border-right: 1px solid #B3B3B3;
          }
          &.active {
            background: $color-ci-blue;
            color: #fff;
          }
        }
      }
      .tabs {
        min-height: 250px;
        .tab {
          display: none;
          &.active {
            display: block;
          }
          > .headline {
            @include font-headline-secondary();
            margin-bottom: 25px;
          }
          .add-language-wrapper {
            padding-top: 10px;
            z-index: 2;
            .add-language {
              margin-top: 10px;
              .icon {
                display: inline-block;
                margin-right: 10px;
                vertical-align: baseline;
              }
              .label {
                display: inline-block;
                color: $color-ci-blue;
                vertical-align: middle;
              }
            }
          }
          .languages-settings {
            .field {
              position: relative;
              z-index: 12;
            }
            .section {
              position: relative;
              z-index: 0;
              &:not(:last-child) {
                margin-bottom: 20px;
              }
              &.default-language {
                z-index: 2;
              }
              &.select-languages {
                z-index: 1;
                .button .label {
                  margin: 0;
                }
              }
              &.types {
                .tab-buttons {
                  position: relative;
                  height: 31px;
                  margin: 0 -75px;
                  padding: 0;
                  box-shadow: none;
                  .tab-button {
                    @include font-regular(14px);
                    padding: 2px 0 0;
                    line-height: 29px;
                    text-transform: none;
                    &.active {
                      box-shadow: $box-shadow-button;
                    }
                  }
                }
                .tabs {
                  margin: 0 -75px -40px;
                  .tab:last-child .column {
                    align-items: center;
                  }
                  .table {
                    .table-head, .table-body {
                      position: relative;
                      z-index: 1;
                      .dropdown {
                        border-bottom: none !important;
                      }
                      .row {
                        display: flex;
                        position: relative;
                        &:before {
                          opacity: 0.1;
                          position: absolute;
                          height: 1px;
                          content: "";
                          background: $color-ci-blue;
                          bottom: 0;
                          right: 0;
                          left: 0;
                        }
                        &:not(.highlighted) {
                          padding-top: 5px;
                        }
                        &.highlighted {
                          background: #F2F4F5;
                          &:before {
                            display: none;
                          }
                        }
                        .column {
                          width: 40%;
                          padding: 5px 20px;
                          text-transform: uppercase;
                          text-align: left;
                          line-height: 20px;
                          &:first-child {
                            width: 20%;
                            padding-left: 40px;
                          }
                          &:last-child {
                            padding-right: 40px;
                          }
                          .link {
                            @include font-bold(12px);
                            color: $color-ci-pink;
                            .icon {
                              vertical-align: middle;
                              margin-left: 5px;
                            }
                          }
                        }
                      }
                    }
                    .table-body {
                      z-index: 0;
                      max-height: 275px;
                      overflow: auto;
                      .row .column {
                        display: flex;
                        text-transform: none;
                        .input-image {
                          .title {
                            font-size: 11px;
                          }
                          button {
                            padding: 7px;
                            svg path {
                              fill: #fc45f0;
                            }
                          }
                        }
                        input[type="text"], textarea {
                          border: none;
                          line-height: 20px;
                          padding: 0;
                          font-size: 14px;
                          &::-webkit-input-placeholder {
                            opacity: 0.4;
                            line-height: 20px;
                            color: $color-ci-blue;
                            font-size: 14px;
                          }
                          &::-moz-placeholder {
                            opacity: 0.4;
                            line-height: 20px;
                            color: $color-ci-blue;
                            font-size: 14px;
                          }
                          &:-ms-input-placeholder {
                            opacity: 0.4;
                            line-height: 20px;
                            color: $color-ci-blue;
                            font-size: 14px;
                          }
                          &:-moz-placeholder {
                            opacity: 0.4;
                            line-height: 20px;
                            color: $color-ci-blue;
                            font-size: 14px;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .label {
                @include font-bold(12px);
                margin-bottom: 10px;
                text-transform: uppercase;
              }
              .field {
                max-width: 186px;
                margin: 0 auto;
              }
              .add-edit {
                .icon {
                  margin: 0;
                }
                .label {
                  margin: 0;
                }
              }
              .flags {
                margin: -10px -2px -2px;
                font-size: 0;
                .flag {
                  display: inline-block;
                  width: 20px;
                  margin: 2px;
                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
          .form > .field {
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 20;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            .button.add-edit {
              float: left;
              margin-left: 0;
            }
            .category {
              @include font-regular(14px);
              text-align: left;
              text-transform: uppercase;
              margin-bottom: 12px;
            }
            > * {
              margin-bottom: 18px;
            }
          }
          .button.highlighted {
            margin-top: 10px;
          }
          .history-columns {
            display: flex;
            margin: 0 -75px -40px;
            padding: 0 15px;
            overflow: auto;
            .column {
              flex: 1;
              max-height: 400px;
              &:not(:last-child) .headline {
                border-right: 1px solid #fff;
              }
              .headline {
                @include font-regular(14px);
                padding: 10px;
                margin-bottom: 18px;
                background: #f2f4f5;
                line-height: 15px;
                text-transform: uppercase;
              }
              ul {
                width: 100%;
                max-width: 350px;
                margin: 0 auto;
                padding: 10px 0;
                li {
                  &:not(:last-child) {
                    margin-bottom: 20px;
                  }
                  &:last-child {
                    margin-bottom: 40px;
                  }
                  button {
                    @include font-regular(9px);
                    position: relative;
                    width: 100%;
                    padding: 5px;
                    border: 1px solid #525F9F;
                    line-height: 15px;
                    transition: border $duration-hover;
                    letter-spacing: 0.1em;
                    &.inactive {
                      opacity: 0.5;
                    }
                    &.active {
                      border-bottom: 2px solid $color-ci-pink;
                    }
                    &:hover .hover {
                      opacity: 1;
                    }
                    .name {
                      @include font-bold(9px);
                      letter-spacing: 0.1em;
                    }
                    .hover {
                      @include font-bold(8px);
                      opacity: 0;
                      position: absolute;
                      top: -1px;
                      right: -1px;
                      bottom: -1px;
                      left: -1px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background: $color-ci-purple-gradient-diagonal;
                      color: #fff;
                      text-transform: uppercase;
                      transition: opacity $duration-hover;
                      .icon {
                        display: inline-block;
                        font-size: 0;
                        margin-right: 10px;
                        svg path {
                          fill: #fff;
                        }
                      }
                      .label {
                        padding-top: 1px;
                        letter-spacing: 0.1em;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .button.add-edit {
        color: $color-font-primary;
        float: left;
      }
      .input-image {
        display: flex;
        align-items: center;
        justify-content: left;
        .preview {
          float: left;
          height: 22px;
          border: 1px solid $color-font-primary;
          cursor: pointer;
          img {
            height: 100%;
          }
        }
        .title {
          @include font-regular(9px);
          flex: 1;
          padding: 0 15px;
          text-align: left;
          cursor: pointer;
        }
        button {
          width: 27px;
          height: 27px;
          padding: 8px;
          &:last-child {
            margin-right: -8px;
          }
          svg {
            float: left;
            width: 100%;
            height: 100%;
            path {
              fill: $color-font-primary;
            }
          }
        }
      }
    }
    .close-modal {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      background: $color-ci-purple-gradient-diagonal;
      //box-shadow: $box-shadow-primary;
      svg path {
        fill: #fff;
      }
    }
  }
  .modal-background {
    opacity: 0.8;
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-ci-blue;
  }
}
#modal .modal-window.comment-file {
  padding: 40px 0 0;
  max-width: 900px;
  .headline {
    font-size: 60px;
    font-weight: 100;
    text-transform: none;
  }
  .attachment-info-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: $color-ci-blue;
    min-height: 380px;
    @media (max-height: 768px) {
      top: 0;
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      min-width: 0;
      height: 50px;
      padding: 0;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .image {
      position: relative;
      width: 50%;
      .img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color-ci-blue url() no-repeat center;
        background-size: contain;
      }
    }
    .video {
      position: relative;
      width: 50%;
      padding: 1px;
      video {
        width: 100%;
      }
    }
    .audio {
      position: relative;
      width: 50%;
      padding: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      audio {
        width: 100%;
      }
    }
    .info {
      display: flex;
      flex-flow: column;
      justify-content: center;
      flex: 1;
      padding: 30px 35px;
      color: #fff;
      .headline {
        @include font-headline-secondary();
        text-align: center;
      }
      .key-info {
        display: flex;
        margin-top: 25px;
        margin-bottom: 10px;
        text-align: center;
        .column {
          flex: 1;
          .label {
            @include font-bold(12px);
            text-transform: uppercase;
          }
        }
      }
      .inputs {
        display: flex;
        flex-flow: wrap;
        margin: 0 -20px;
        .input {
          width: calc(100% - 40px);
          margin: 0 20px;
          &:not(:last-child) {
            margin-bottom: 15px;
          }
          &.w50 {
            width: calc(50% - 40px);
          }
        }
      }
    }
    .bottom-buttons {
      text-align: center;
      padding: 0 10px 30px;
      .button {
        width: calc(50% - 50px);
        min-width: 0;
        margin: 0 25px;
        padding: 12px 25px 10px;
      }
    }
  }
}
