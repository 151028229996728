.editor-drop-indicator {
  height: 0;
  background: $color-ci-purple;
  transition: $duration-hover;
  &.show {
    height: 10px;
    transform: none !important;
    &.editor-drop-indicator-before {
      margin: 0 0 5px;
    }
    &.editor-drop-indicator-left {
      width: 10px;
    }
    &.editor-drop-indicator-right {
      width: 10px;
    }
    &.editor-drop-indicator-after {
      margin: 5px 0 0;
    }
  }
  &.type-module {
    background: $color-ci-blue;
  }
  &.editor-drop-indicator-left, &.editor-drop-indicator-right {
    position: absolute;
    top: 0;
    width: 0;
    height: 100%;
    transform: scaleX(0);
  }
  &.editor-drop-indicator-left {
    left: -15px;
  }
  &.editor-drop-indicator-right {
    right: -15px;
  }
}
