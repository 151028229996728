.tooltip {
  position: absolute !important;
  z-index: 10000;
  width: 158px;
  padding: 12px 12px 15px;
  box-shadow: $box-shadow-tooltip;
  text-align: center;
  margin: 0 !important;
  margin-left: -79px !important;
  border-radius: 10px;
  pointer-events: auto;
  &:before {
    position: absolute;
    z-index: 4;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: #fff;
    border: 1px solid $color-border-grey;
    transition: border $duration-hover;
    border-radius: 10px;
  }
  > * {
    z-index: 10;
    position: relative;
  }
  p {
    @include font-regular(11px);
    text-align: center;
    color: $color-ci-blue;
  }
  .watch-video {
    @include font-bold(10px);
    color: $color-ci-purple;
    text-transform: uppercase;
    margin-top: 10px;
    .icon {
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 5px;
      display: inline-flex;
    }
  }
  .button {
    @include font-bold(8px);
    position: relative;
    min-width: 0;
    margin-top: 12px;
    padding: 7px 20px 6px;
    background: #fff !important;
    color: $color-font-primary !important;
    line-height: 12px;
    text-transform: uppercase;
    appearance: none;
    transition: box-shadow $duration-hover, color $duration-hover;
    &:hover {
      color: #fff;
      &:before {
        border-color: $color-border-purple;
      }
      &:after {
        opacity: 1;
      }
    }
    &:before {
      display: block !important;
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid $color-border-grey;
      content: "";
      transition: border $duration-hover;
      border-radius: 100px;
    }
    &:after {
      display: block !important;
      opacity: 0;
      position: absolute;
      z-index: 5;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $color-ci-purple-gradient-diagonal;
      box-shadow: $box-shadow-button;
      content: "";
      transition: opacity $duration-hover;
      border-radius: 100px;
    }
    &.highlighted {
      color: #fff !important;
      &:before {
        background: $color-ci-purple-gradient-diagonal;
        border-color: $color-border-purple;
      }
    }
  }
  .arrow {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 20px;
    .after, .before {
    	top: calc(100% - 1px);
    	left: 50%;
    	border: solid transparent;
    	content: " ";
    	height: 0;
    	width: 0;
    	position: absolute;
    	pointer-events: none;
    }
    .after {
    	border-color: transparent;
    	border-top-color: #fff;
    	border-width: 10px;
    	margin-left: -10px;
    }
    .before {
    	border-color: transparent;
    	border-top-color: $color-border-grey;
    	border-width: 11px;
    	margin-left: -11px;
    }
  }
}
