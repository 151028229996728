.add-button {
  display: block;
  width: calc(100% + 84px);
  height: 21px;
  margin: -38px -42px 37px;
  padding: 5px;
  background: $color-ci-blue-gradient-vertical;
  transition: box-shadow $duration-hover;
	@media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
	}
  &:hover {
    box-shadow: $box-shadow-button;
  }
  &:first-child {
    margin-top: 37px;
  }
  svg {
    width: 100%;
    height: 100%;
    rect {
      fill: #fff;
    }
  }
}
