@font-face {
  font-family: 'DIN 2014';
  src: url('../fonts/DIN2014-ExtraLight.eot');
  src: url('../fonts/DIN2014-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DIN2014-ExtraLight.svg#DIN2014-ExtraLight') format('svg'),
    url('../fonts/DIN2014-ExtraLight.ttf') format('truetype'),
    url('../fonts/DIN2014-ExtraLight.woff') format('woff'),
    url('../fonts/DIN2014-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'DIN 2014';
  src: url('../fonts/DIN2014-Light.eot');
  src: url('../fonts/DIN2014-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DIN2014-Light.svg#DIN2014-Light') format('svg'),
    url('../fonts/DIN2014-Light.ttf') format('truetype'),
    url('../fonts/DIN2014-Light.woff') format('woff'),
    url('../fonts/DIN2014-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DIN 2014';
  src: url('../fonts/DIN2014-Regular.eot');
  src: url('../fonts/DIN2014-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DIN2014-Regular.svg#DIN2014-Regular') format('svg'),
    url('../fonts/DIN2014-Regular.ttf') format('truetype'),
    url('../fonts/DIN2014-Regular.woff') format('woff'),
    url('../fonts/DIN2014-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DIN 2014';
  src: url('../fonts/DIN2014-Bold.eot');
  src: url('../fonts/DIN2014-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DIN2014-Bold.svg#DIN2014-Bold') format('svg'),
    url('../fonts/DIN2014-Bold.ttf') format('truetype'),
    url('../fonts/DIN2014-Bold.woff') format('woff'),
    url('../fonts/DIN2014-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
