.button {
  @include font-bold(12px);
  position: relative;
  min-width: 170px;
  padding: 12px 40px 10px;
  background: #fff;
  color: $color-font-primary;
  line-height: 18px;
  text-transform: uppercase;
  appearance: none;
  transition: box-shadow $duration-hover, color $duration-hover;
  &:hover {
    color: #fff;
    &:before {
      border-color: $color-border-purple;
    }
    &:after {
      opacity: 1;
    }
  }
  &:before {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid $color-border-grey;
    content: "";
    transition: border $duration-hover;
  }
  &:after {
    opacity: 0;
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-ci-purple-gradient-diagonal;
    box-shadow: $box-shadow-button;
    content: "";
    transition: opacity $duration-hover;
  }
  &.plus .icon {
    margin: -1px 5px -3px 0;
    vertical-align: middle;
    font-size: 24px;
    font-weight: 300;
  }
  &.highlighted {
    color: #fff;
    &:before {
      background: $color-ci-purple-gradient-diagonal;
      border-color: $color-border-purple;
    }
  }
  &.transparent {
    background: none;
    color: #fff;
    &:hover {
      color: $color-font-primary;
    }
    &:before {
      border-color: #fff;
    }
    &:after {
      background: #fff;
    }
  }
  &.round {
    border-radius: 100px;
    &:before, &:after {
      border-radius: 100px;
    }
  }
  &.add-edit {
    min-width: 0;
    margin: 0 auto;
    padding: 6px 5px 4px;
    background: none;
    box-shadow: none !important;
    border-radius: 0;
    color: #fff;
    &:hover .icon {
      background: $color-ci-pink-hover;
    }
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
    .icon {
      width: 20px;
      height: 20px;
      margin: 0 auto 10px;
      padding: 5px;
      background: $color-ci-pink;
      border-radius: 50%;
      transition: background $duration-hover;
      &.icon-image svg path {
        fill: #fff;
        stroke: none;
      }
      svg {
        float: left;
        width: 100%;
        height: 100%;
        path {
          fill: none;
          stroke: #fff;
        }
      }
    }
  }
  .icon {
    display: inline-block;
    position: relative;
    z-index: 15;
    font-size: 0;
    svg path {
      fill: #fff;
    }
  }
  .label {
    position: relative;
    z-index: 15;
  }
}
