.drag-preview {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 86px;
  height: 86px;
  padding: 3px;
  text-align: center;
  &.is-dragging .drag-preview-container {
    transform: scale(1.15);
    &:before {
      opacity: 1;
    }
  }
  &.type-module .drag-preview-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .label {
      min-height: 0;
    }
    .background:before {
      background: $color-ci-blue-gradient-diagonal;
    }
  }
  .drag-preview-container {
    position: relative;
    z-index: 5;
    width: 100%;
    height: 100%;
    transition: transform $duration-hover;
    cursor: default;
    &:before {
      opacity: 0;
      position: absolute;
      z-index: -2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      //background: $box-shadow-drag-preview-background;
      box-shadow: $box-shadow-drag-preview;
      content: "";
      transition: opacity $duration-hover;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 32px;
      margin-top: 11px;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      svg {
        transform: scale(1);
        circle, path, rect {
          fill: #fff;
          transition: fill $duration-hover;
        }
        line {
          stroke: #fff;
          transition: stroke $duration-hover;
        }
      }
    }
    .label {
      @include font-bold(10px);
      min-height: 22px;
      color: #fff;
      line-height: 1.1;
      text-transform: uppercase;
    }
    .background {
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $color-ci-blue;
      content: "";
      &:before {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color-ci-purple-gradient-diagonal;
        content: "";
        transition: opacity $duration-hover;
      }
    }
  }
}
