.element-empty {
  position: relative;
  z-index: 1;
  .element-empty-container {
    @include font-extralight(24px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 275px;
    text-transform: uppercase;
    user-select: none;
    transition: color $duration-hover;
    &:before {
      position: absolute;
      z-index: -10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAIAAABv85FHAAAANklEQVQImWPYvHnzfxyA8f///wzYwJYtWxiw6oAYhkUObgu6HLL1DLgkUOQwHcyASwIqh8uLABGU1SNxMW8rAAAAAElFTkSuQmCC') repeat center;
      content: "";
    }
    &:after {
      opacity: 0;
      position: absolute;
      z-index: -5;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $color-ci-purple;
      content: "";
      transition: opacity $duration-hover;
    }
    &.is-over {
      color: #fff;
      &:after {
        opacity: 1;
      }
    }
  }
}
.element .element-empty {
  height: 100%;
  min-height: 100px;
  .element-empty-container {
    height: 100%;
    &:before {
      opacity: 1;
    }
  }
}
