.simplebar-wrapper {
  min-height: 100%;
  .simplebar-content-wrapper {
    min-height: 100%;
  }
}
.simplebar-track {
  background: $color-background-darkblue;
  &.simplebar-vertical {
    width: 20px;
  }
  .simplebar-scrollbar {
    &:before {
      opacity: 1;
      top: 0 !important;
      right: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      background: $color-ci-purple-gradient-vertical;
      border-radius: 0;
    }
    &:after {
      position: absolute;
      top: 33.333%;
      right: 5px;
      bottom: 33.333%;
      left: 5px;
      background: transparent url('../../images/icons/scroll-bar-handle.svg') no-repeat center;
      content: "";
    }
  }
}
