.login {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: calc(100vh - 63px);
  padding: 50px;
  color: #fff;
  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-ci-blue url('../../images/background/macbook.jpg') no-repeat center;
    background-size: cover;
    &:before {
      opacity: 0.85;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $color-ci-blue;
      content: "";
    }
  }
  .login-container {
    position: relative;
    width: 100%;
    text-align: center;
    .headline {
      @include font-headline-primary();
      margin-bottom: 45px;
    }
    .login-error {
      color: #ff0000;
      margin: -25px 0 25px;
    }
    .form {
      width: 100%;
      max-width: 380px;
      margin: 0 auto;
      input {
        margin-bottom: 35px;
      }
      button {
        margin-top: 45px;
      }
    }
  }
}
