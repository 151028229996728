#editor-preview {
  position: fixed;
  z-index: 10;
  top: 63px;
  right: 0;
  bottom: 0;
  left: 316px;
  overflow-x: auto;
  transition: left $duration-slide;
  &.preview-mode {
    left: 0;
  }
  .responsive-wrapper {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: $box-shadow-area-secondary;
    transition: width $duration-slide;
    margin: 0 auto;
    overflow: hidden;
    &.desktop {
      width: 1600px;
    }
    &.laptop {
      width: 1280px;
    }
    &.tablet {
      width: 768px;
    }
    &.mobile {
      width: 375px;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
#wbt.element-dragging .wbt-container .modules .module .module-container .elements .element .element-container {
  pointer-events: none;
}
